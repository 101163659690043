import React from 'react';
import {Box, Button, Container, Typography, useTheme} from '@mui/material';
import {styled, useMediaQuery} from '@mui/system';
import {useNavigate} from "react-router-dom";

const HeroBox = styled(Box)(({theme}) => ({
    position: 'relative',
    color: 'white',
    height: 'calc(100vh - 64px)',
    backgroundImage: `url(/background.jpg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {paddingTop: theme.spacing(10)},
    alignItems: 'center',
    textAlign: 'center',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.3)',
    }
}));

const LandingPage = () => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate();
    return (
        <HeroBox>
            <Container sx={{justifyItems: 'center', justifyContent: 'center', position: 'relative', zIndex: 2, p: theme.spacing(4)}}>
                <Typography
                    sx={{
                        textShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        fontStyle: 'italic',
                        fontWeight: 600,
                    }} variant={isMdUp ? 'h3' : 'h5'}
                    component="h1"
                    gutterBottom
                >
                    SYNDICAT DES COMPAGNIES AÉRIENNES DE FRET EN FRANCE
                </Typography>
                <Typography variant={isMdUp ? 'h5' : 'h6'} gutterBottom sx={{fontStyle: 'italic', pt: isMdUp ? 4 : 2}}>
                    Le Sycaff est un organisme professionnel dont le but est de représenter les intérêts de ses membres face aux différents intervenants de la chaîne logistique, afin de participer au développement du transport aérien de fret.
                </Typography>
                <Button onClick={() => navigate('/mission')} variant="contained" color="primary" sx={{mt: 2}}>
                    NOTRE MISSION
                </Button>
                <Button onClick={() => navigate('/contact')} variant="contained" color="error" sx={{mt: 2, ml: isMdUp ? 3 : 0}}>
                    NOUS CONTACTER
                </Button>
            </Container>
        </HeroBox>
    );
};

export default LandingPage;