import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {CssBaseline} from '@mui/material';
import Navbar from "./Components/Navbar";
import LandingPage from "./Components/Pages/LandingPage";
import Contact from "./Components/Pages/Contact";
import Association from "./Components/Pages/Association";
import Mission from "./Components/Pages/Mission";
import {LoadScript} from "@react-google-maps/api";

function App() {
    return (
        <Router>
            <CssBaseline/>
            <Navbar/>
            <LoadScript googleMapsApiKey="AIzaSyAWgurs-TN5GuGblIimqSa6fGbAvNBUjg8">
                <Routes>
                    <Route path="/" element={<LandingPage/>}/>
                    <Route path="/mission" element={<Mission/>}/>
                    <Route path="/association" element={<Association/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>
            </LoadScript>
        </Router>
    );
}

export default App;
