import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Autoplay, Pagination, Navigation} from 'swiper/modules';
import {Grid, useTheme} from "@mui/material";
import {useMediaQuery} from "@mui/system";
import {useMemo} from "react";

export default function Carrousel({imagesArray}) {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const slidesPerViewDynamic = useMemo(() => {
        return isMdUp ? Math.min(imagesArray.length, 5) : Math.min(imagesArray.length, 2)
    }, [imagesArray.length, isMdUp]);

    // Calculez la largeur totale du conteneur Swiper en fonction du nombre de diapositives et de l'espacement
    const swiperContainerWidth = useMemo(() => {
        const slideWidth = isMdUp ? 200 : 130; // Largeur de chaque diapositive (ajustez selon votre conception)
        const spaceBetweenSlides = isMdUp ? 50 : 20; // Espace entre les diapositives
        return (slideWidth + spaceBetweenSlides) * imagesArray.length; // Soustrayez spaceBetweenSlides à la fin pour compenser l'ajout supplémentaire
    }, [imagesArray.length, isMdUp]);

    const swiperContainerStyles = {
        maxWidth: swiperContainerWidth,
        marginRight: 'auto',
        marginLeft: 'auto'
    };

    return (
        <Grid item xs={12}>
            <div style={swiperContainerStyles}>
                <Swiper
                    spaceBetween={isMdUp ? 50 : 20}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                        waitForTransition: false
                    }}
                    loop={imagesArray.length > slidesPerViewDynamic}
                    slidesPerView={slidesPerViewDynamic}
                    modules={[Autoplay, Pagination, Navigation]}
                >
                    {imagesArray.map((image, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <img style={{maxHeight: isMdUp ? 200 : 130, height: '100%'}} src={image.url} alt={`${index}`}/>
                                </SwiperSlide>
                            )
                        }
                    )}
                </Swiper>
            </div>
        </Grid>
    );
}