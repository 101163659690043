import {Divider, Grid, Typography, useTheme} from "@mui/material";
import {useMediaQuery} from "@mui/system";
import Carrousel from "./Carrousel";

const adherants = [
    {url: `/Adherents/AIR-TAHITI-NUI.png`},
    {url: `/Adherents/Air_France-KLM_Logo.png`},
    {url: `/Adherents/AIRCANADA.png`},
    {url: `/Adherents/American_Airlines.png`},
    {url: `/Adherents/Cathay-Pacific.png`},
    {url: `/Adherents/China-Southern-Airlines.png`},
    {url: `/Adherents/chinaairlines.png`},
    {url: `/Adherents/Delta.png`},
    {url: `/Adherents/DHL-Aviation.png`},
    {url: `/Adherents/Emirates_SkyCargo.png`},
    {url: `/Adherents/EvaAir.png`},
    {url: `/Adherents/Fedex.png`},
    {url: `/Adherents/JallCargo.png`},
    {url: `/Adherents/Korean-air.png`},
    {url: `/Adherents/Lufthansa-1.png`},
    {url: `/Adherents/Martinair-cargo.png`},
    {url: `/Adherents/Nippon_Airways.png`},
    {url: `/Adherents/Qatar-Airways.png`},
    {url: `/Adherents/swissworldcargo.png`},
    {url: `/Adherents/ThaiAirways.png`},
    {url: `/Adherents/UnitedAirlines.png`},
]

const gsa = [
    {url: `/GSA/aerocargo.png`},
    {url: `/GSA/AirCargoTrader.png`},
    {url: `/GSA/Airnautic.png`},
    {url: `/GSA/ATC-Aviation.png`},
    {url: `/GSA/russ-cargo.png`},
]
const gha = [
    {url: `/GHA/Groupe-REM.png`},
    {url: `/GHA/WFS-LOGO.png`},
    {url: `/GHA/geh.png`},
]

const staff = [
     {
        name: 'Véronique Darche',
        role: 'Membre du bureau',
        description: 'Chef de service – Gestion des fournisseurs de services au sol Manager, GHA vendor management',
        sexe: 'F'
    },  {
        name: 'Karin Donati',
        role: 'Présidente Commission Douane & Sureté. Trésorére',
        description: 'Responsable Douane Air France Cargo',
        sexe: 'F'
    }, {
        name: 'Thierry Duchesne',
        role: 'Président',
        description: 'Cargo manager Lufthansa Cargo',
        sexe: 'H'
    }, {
        name: 'Jean Marc Baduel',
        role: 'Vice Président',
        description: 'Cargo Manager France. EVA Airways',
        sexe: 'H'
    },{
        name: 'Marine Gruzelle',
        role: 'Membre du bureau',
        description: 'EVA Airways',
        sexe: 'F'
    },
]

function StaffCard({name, role, description, sexe}) {
    return (
        <Grid item container xs={12} sx={{pt: 1}}>
            <Grid item container justifyContent={'center'} xs={12}>
                <img alt={"imgStaff"} style={{paddingLeft: 8, height: '100%', maxHeight: 70, cursor: 'pointer'}} src={sexe === 'H' ? `${process.env.PUBLIC_URL}/homme.png` : `${process.env.PUBLIC_URL}/femme.png`}/>
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
                <Typography sx={{fontWeight: 600, fontSize: 14}}>{name}</Typography>
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
                <Typography sx={{color: 'gray', fontStyle: 'italic', fontSize: 12, textAlign: 'center'}}>{role}</Typography>
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
                <Divider sx={{width: '90%'}}/>
            </Grid>
            <Grid item container justifyContent={'center'} xs={12} sx={{pt: 1}}>
                <Typography sx={{color: 'gray', fontSize: 14, textAlign: 'center'}}>{description}</Typography>
            </Grid>
            <Grid item xs={12}>
            </Grid>
        </Grid>
    )
}

export default function Association() {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid container justifyContent={'center'} sx={{height: 'calc(100vh - 64px)', overflow: 'auto'}}>
            <Grid container xs={12} alignContent={'center'} justifyContent={'center'} sx={{height: isMdUp ? '25%' : '50%', p: isMdUp ? 3 : 2}}>
                <Grid item xs={9}>
                    <Typography sx={{fontWeight: 500, textAlign: 'center', fontStyle: 'italic', color: 'gray', fontSize: isMdUp ? '1.25em' : '1em'}}>Le Sycaff est un organisme professionnel dont le but est de représenter les intérêts de ses membres face aux différents intervenants de la chaîne
                        logistique, afin de participer au développement du transport aérien de fret. Il s’agit d’une association loi 1901 à but non lucratif régit par une charte.</Typography>
                </Grid>
            </Grid>
            <Grid container alignContent={'flex-start'} xs={12} sx={{height: 'auto', p: 3, pt: 0}}>
                <Grid item container justifyContent={'center'} xs={12}>
                    <Typography color={'primary'} sx={{fontWeight: 500}} variant={'h5'}>L’ÉQUIPE</Typography>
                </Grid>
                <Grid item xs={12} sx={{pt: 0.5}}>
                    <Divider/>
                </Grid>
                <Grid item container justifyContent={'center'} alignItems={'flex-start'} spacing={2} sx={{pt: 2}}>
                    {staff.map((staff, index) => (
                        <Grid key={index} item md={3} xs={6}>
                            <StaffCard name={staff.name} sexe={staff.sexe} role={staff.role} description={staff.description}/>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid container alignContent={'flex-start'} xs={12} sx={{height: '100%', p: 4}}>
                <Grid item container justifyContent={'center'} xs={12}>
                    <Typography color={'error'} sx={{fontWeight: 500}} variant={'h5'}>LES MEMBRES</Typography>
                </Grid>
                <Grid item xs={12} sx={{pt: 0.5}}>
                    <Divider/>
                </Grid>
                <Grid container justifyContent={'center'} item xs={12} sx={{pt: 3}}>
                    <Grid item container xs={12}>
                        <Typography sx={{fontWeight: 500}} variant={'h6'}>ADHERANTS</Typography>
                    </Grid>
                    <Grid item  container xs={12}>
                        <Carrousel imagesArray={adherants}/>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} item xs={12} sx={{pt: 3}}>
                    <Grid item container xs={12}>
                        <Typography sx={{fontWeight: 500}} variant={'h6'}>GSA</Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Carrousel imagesArray={gsa}/>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} item xs={12} sx={{pt: 3}}>
                    <Grid item container xs={12}>
                        <Typography sx={{fontWeight: 500}} variant={'h6'}>GHA</Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        <Carrousel imagesArray={gha}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}