import {Grid, TextField, Typography, useTheme} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {useMediaQuery} from "@mui/system";
import {useState} from "react";
import {GoogleMap, Marker} from '@react-google-maps/api';

const containerStyle = {
    width: '100%', // Ajustez la taille comme nécessaire
    height: '410px' // Ajustez la hauteur comme nécessaire
};

const center = {
    lat: 48.9875248280, // Latitude pour 10 Rue du Pavé
    lng: 2.53944952883 // Longitude pour 10 Rue du Pavé
};
export default function Contact() {
    const address = `10 Rue du Pavé\n BP12245\n 95703\n Roissy CDG`
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const [isSaving, setIsSaving] = useState(false)
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

    const submitContact = async () => {
        setIsSaving(true)
        let mailTo = `mailto:info@sycaff.com?subject=${subject}&body=${message}`;
        document.location = mailTo;
        setIsSaving(false)
        setMessage('')
        setSubject('')
    }

    const onLoad = marker => {
        console.log('marker: ', marker)
    }

    return (
        <Grid container justifyContent={'center'} sx={{height: 'calc(100vh - 64px)', overflow: 'auto', pb: 3}}>
            <Grid container alignContent={'flex-start'} item xl={3} md={4.5} xs={12} sx={{height: isMdUp ? '100%' : 'auto', p: isMdUp ? 4 : 2}}>
                <form style={{width: '100%'}} onSubmit={submitContact}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: 600}} variant={'h6'}>Contact:</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{pt: 2}}>
                        <TextField value={subject} onChange={(event) => setSubject(event.target.value)} required fullWidth label="Sujet" variant="filled"/>
                    </Grid>
                    <Grid item xs={12} sx={{pt: 2}}>
                        <TextField value={message} onChange={(event) => setMessage(event.target.value)} required multiline rows={8} fullWidth label="Message" variant="filled"/>
                    </Grid>
                    <Grid item xs={12} sx={{pt: 2}}>
                        <LoadingButton loading={isSaving} type={'submit'} variant={'contained'}>
                            Envoyer
                        </LoadingButton>
                    </Grid>
                </form>
            </Grid>
            <Grid container item xl={4} md={4.5} xs={12} sx={{height: isMdUp ? '100%' : 'auto', p: isMdUp ? 4 : 2}}>
                <GoogleMap
                    id={'toto'}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                >
                    <Marker icon={{scale: 10}} onLoad={onLoad} label={'Sycaff'} position={center}/>
                </GoogleMap>
            </Grid>
            <Grid container alignContent={'flex-start'} item xl={3} md={3} xs={12} sx={{height: isMdUp ? '100%' : 'auto', p: 4}}>
                <Grid item xs={12}>
                    <Typography color={'primary'} sx={{fontWeight: 600}} variant={'h6'}>Nous Trouver:</Typography>
                </Grid>
                <Grid item xs={12} sx={{pt: 1}}>
                    <Typography sx={{whiteSpace: 'pre-line', color: 'gray'}} variant={'body1'}>
                        {address}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{pt: 2}}>
                    <Typography color={'error'} sx={{fontWeight: 600}} variant={'h6'}>Nous Ecrire:</Typography>
                </Grid>
                <Grid item xs={12} sx={{pt: 1}}>
                    <Typography sx={{whiteSpace: 'pre-line', color: 'gray'}} variant={'body1'}>
                        info@sycaff.com
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}