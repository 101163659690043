import {AppBar, Box, Drawer, IconButton, MenuItem, Tab, Tabs, Toolbar} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import {useState} from "react";

export default function Navbar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const location = useLocation();
    const currentTab = location.pathname;
    const handleClick = (event) => {
        if (anchorEl)
            setAnchorEl(null);
        else
            setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();

    const handleChangeTab = (event, newValue) => {
        navigate(newValue);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar position="static" sx={{bgcolor: 'white', zIndex: '1101 !important', height: '64px'}}>
                <Toolbar disableGutters>
                    <img alt={"Sycaff"} onClick={() => navigate('/')} style={{paddingLeft: 8, height: '100%', cursor: 'pointer'}} src={`${process.env.PUBLIC_URL}/sycaffLogo.png`}/>
                    <Box sx={{ml: 'auto', mr: 1, display: {xs: 'block', sm: 'none'}}}>
                        <IconButton onClick={handleClick}>
                            <MenuIcon/>
                        </IconButton>
                    </Box>
                    <Box sx={{ml: 'auto', mr: 1, display: {xs: 'none', sm: 'block'}}}>
                        <Tabs value={currentTab} onChange={handleChangeTab} aria-label="Navigation Tabs">
                            <Tab label="Notre mission" value="/mission" />
                            <Tab label="L'association" value="/association" />
                            <Tab label="Contact" value="/contact" />
                        </Tabs>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{zIndex: '1100 !important', flexShrink: 0,}}
                anchor={'top'}
                open={open}
                onClose={handleClose}
            >
                <Toolbar/>
                <MenuItem onClick={() => {
                    handleClose()
                    navigate('/mission')
                }}>
                    Notre mission
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    navigate('/association')
                }}>
                    L'association
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    navigate('/contact')
                }}>
                    Contact
                </MenuItem>
            </Drawer>
        </Box>
    )
}