import {Divider, Grid, Typography, useTheme} from "@mui/material";
import {useMediaQuery} from "@mui/system";
import Done from '@mui/icons-material/Done';

export default function Mission() {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const missionText = ' Échanger sur des problématiques techniques ou opérationnelles entre experts, utilisateurs et, ou Institutionnels.\n' +
        '                        Proposer des solutions concrètes pouvant faire avancer de multiples sujets ayant un intérêt majeur pour l’association toute entière ainsi qu’à tous les utilisateurs de la plateforme de fret.\n' +
        '                        Organiser une fois par trimestre une réunion d’information à l’ensemble de l’organisation sur les différents points d’avancement des sujets traités dans les commissions.\n' +
        '                    '
    const objectifsText = 'Améliorer la qualité de la prestation rendue aux clients.\n' +
        'Effectuer une veille stratégique, économique et règlementaire.\n' +
        'Développer un partenariat stratégique.'


    return (
        <Grid container justifyContent={'right'} sx={{height: 'calc(100vh - 64px)'}}>
            <Grid item xl={7} md={8.25} xs={12} sx={{height: '100%', overflow: 'auto', p: isMdUp ? 3 : 2}}>
                <Grid item xs={12}>
                    <Typography sx={{fontWeight: 600}} variant={'h6'}> Missions</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sx={{pt: 1}}>
                    <Typography sx={{color: 'gray', whiteSpace: 'pre-line'}} variant={'body1'}>
                        {missionText}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{pt: 3}}>
                    <Typography sx={{fontWeight: 600}} variant={'h6'}> Activites</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sx={{pt: 1}}>
                    <Typography sx={{color: 'gray', whiteSpace: 'pre-line'}} variant={'body1'}>
                        Le SYCAFF a pour vocation d’analyser et de diffuser des informations de nature économiques et règlementaires auprès de ses adhérents. Notre association entend défendre au mieux les intérêts du secteur du fret aérien auprès des pouvoirs publics. Ses activités reposent sur
                        l’engagement et le travail fournis par les commissions.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{pt: 3}}>
                    <Typography sx={{fontWeight: 600}} variant={'h6'}> Objectifs</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sx={{pt: 1}}>
                    <Typography sx={{color: 'gray', whiteSpace: 'pre-line'}} variant={'body1'}>
                        {objectifsText}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{pt: 3}}>
                    <Typography sx={{fontWeight: 600}} variant={'h6'}> Atouts</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item container xs={12} sx={{pt: 1}}>
                    <Grid item xs={12} md={6} container>
                        <Done color={'success'}/>
                        <Typography sx={{color: 'gray', pl: 1}} variant={'body1'}>
                            20 ans d’existence
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} container>
                        <Done color={'success'}/>
                        <Typography sx={{color: 'gray', pl: 1}} variant={'body1'}>
                            Commissions expérimentées
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} container>
                        <Done color={'success'}/>
                        <Typography sx={{color: 'gray', pl: 1}} variant={'body1'}>
                            37 adhérents et membres associés
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} container>
                        <Done color={'success'}/>
                        <Typography sx={{color: 'gray', pl: 1}} variant={'body1'}>
                            Actions engagées et abouties
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xl={3} md={3.75} xs={12} sx={{height: '100%', p: isMdUp ? 3 : 2, borderLeft: isMdUp ? 1 : 0, borderColor: 'divider'}}>
                <Grid item xs={12}>
                    <Typography color="error" sx={{fontWeight: 600}} variant={'h6'}>Comment adhérer à l'association ?</Typography>
                </Grid>
                <Grid item xs={12} sx={{pt: 1}}>
                    <Typography sx={{color: 'gray'}} variant={'body1'}>Rejoindre le SYCAFF, c’est rencontrer des acteurs du fret aérien qui ont les mêmes préoccupations que vous. Notre association est un lieu d’échange, d’analyse et de réflexion.</Typography>
                </Grid>
                <Grid item xs={12} sx={{pt: 2}}>
                    <Typography sx={{color: 'gray', textDecoration: 'underline'}} variant={'body2'}>
                        Cotisations annuelles 2024:
                    </Typography>
                    <ol style={{listStyleType: 'disc', padding: 0, paddingTop: 4, paddingLeft: 16, margin: 0}}>
                        <li style={{color: 'gray'}}>
                            <Typography sx={{color: 'gray', listStyleType: 'disc'}} variant={'body2'}>
                                570 € pour les compagnies aériennes
                            </Typography>
                        </li>
                        <li style={{color: 'gray'}}>
                            <Typography sx={{color: 'gray', listStyleType: 'disc'}} variant={'body2'}>
                                1140 € pour les GSA
                            </Typography>
                        </li>
                    </ol>
                </Grid>
                <Grid item xs={12} sx={{pt: 1}}>
                    <Typography sx={{color: 'gray', textDecoration: 'underline'}} variant={'body2'}>
                        Processus d'inscription :
                    </Typography>
                    <ol style={{listStyleType: 'decimal', paddingTop: 4, padding: 0, paddingLeft: 16, margin: 0}}>
                        <li style={{color: 'gray'}}>
                            <Typography sx={{color: 'gray', listStyleType: 'disc'}} variant={'body2'}>
                                Téléchargement du bulletin d'adhésion
                            </Typography>
                        </li>
                        <li style={{color: 'gray'}}>
                            <Typography sx={{color: 'gray', listStyleType: 'disc'}} variant={'body2'}>
                                Acceptation de la charte
                            </Typography>
                        </li>
                        <li style={{color: 'gray'}}>
                            <Typography sx={{color: 'gray', listStyleType: 'disc'}} variant={'body2'}>
                                Règlement de la cotisation annuelle
                            </Typography>
                        </li>
                    </ol>
                </Grid>
                <Grid container justifyContent={'center'} sx={{pt: 4}}>
                    <Grid alignContent={'flex-start'} item container justifyContent={'center'} xs={5}>
                        <Grid item container justifyContent={'center'} xs={12} sx={{pb: 0.5}}>
                            <a href={`${process.env.PUBLIC_URL}/bulletin-Adhésion-Sycaff.pdf`} target="_blank" rel="noopener noreferrer">
                                <Grid item container justifyContent={'center'} xs={12}>
                                    <img alt={"signContract"} style={{paddingLeft: 8, height: '100%', maxHeight: 70, cursor: 'pointer'}} src={`${process.env.PUBLIC_URL}/signContract.png`}/>
                                </Grid>
                                <Grid item container justifyContent={'center'} xs={12} sx={{pt: 1}}>
                                    <Typography variant={'caption'} sx={{textAlign: 'center', fontStyle: 'italic'}}>
                                        Le formulaire d'inscription
                                    </Typography>
                                </Grid>
                            </a>
                        </Grid>

                    </Grid>
                    <Grid alignContent={'flex-start'} item container justifyContent={'center'} xs={5}>
                        <Grid item container justifyContent={'center'} xs={12} sx={{pb: 0.5}}>
                            <a href={`${process.env.PUBLIC_URL}/CHARTE-DES-MEMBRES-DU-SYCAFF.pdf`} target="_blank" rel="noopener noreferrer">
                                <Grid item container justifyContent={'center'} xs={12}>
                                    <img alt={"signContract"} style={{paddingLeft: 8, height: '100%', maxHeight: 70, cursor: 'pointer'}} src={`${process.env.PUBLIC_URL}/charte.png`}/>
                                </Grid>
                                <Grid item container justifyContent={'center'} xs={12} sx={{pt: 1}}>
                                    <Typography variant={'caption'} sx={{textAlign: 'center', fontStyle: 'italic'}}>
                                        La Charte
                                    </Typography>
                                </Grid>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}